import _ from 'lodash'
import { v4 as uuid } from 'uuid'

const FIELDS_FOR_GENERATE_ADDRESS_API = [
  'region_type',
  'region',
  'district',
  'district_type',
  'locality_type',
  'locality',
  'city_type',
  'city',
  'city_district',
  'city_district_type',
  'street_type',
  'street',
  'house_type',
  'house',
  'block_type',
  'block_number',
  'apartment_type',
  'apartment_number'
]

const FIELDS_FOR_GENERATE_ADDRESS = [
  'regionType',
  'region',
  'district',
  'districtType',
  'localityType',
  'locality',
  'cityType',
  'city',
  'cityDistrict',
  'cityDistrictType',
  'streetType',
  'street',
  'houseType',
  'house',
  'blockType',
  'block',
  'apartmentType',
  'apartmentNumber'
]

export function generateValue(_fields = {}, isFromApi = true) {
  const fields = {}
  const FIELDS_MAP = isFromApi ? FIELDS_FOR_GENERATE_ADDRESS_API : FIELDS_FOR_GENERATE_ADDRESS
  FIELDS_MAP.forEach((key) => {
    if (_fields[key]) fields[key] = _fields[key]
  })
  const regionType = _.get(fields, 'region_type') || _.get(fields, 'regionType')

  if (regionType && regionType.match(/респ/i)) {
    fields.region_type = 'Респ'
    fields.regionType = 'Респ'
  }

  const addressValues = _.values(fields)
  const pairs = _.chunk(addressValues, 2)
  const filledPairs = _.reject(pairs, pair => _.isEmpty(_.compact(pair)))

  return filledPairs
    .map((el, index) => el.join(index === 0 ? ' ' : '. '))
    .join(', ')
}

export const addressMap = ({ address }) => {
  const kladrID = address.kladr_id ?? address.kladrID
  const newKladrID = uuid()
  const resolve = {
    id: _.get(address, '_id', '') || _.get(address, 'id', '') || kladrID || newKladrID,
    kladrID: kladrID || newKladrID,
    isWithoutKladr: !kladrID,
    apartmentNumber: _.get(address, 'apartment_number', '') || _.get(address, 'flat', ''),
    apartmentType: _.get(address, 'apartment_type', '') || _.get(address, 'flat_type', ''),
    block: _.get(address, 'block', null) || _.get(address, 'block_number', null),
    blockNumber: _.get(address, 'block_number', null),
    blockType: _.get(address, 'block_type', null),
    city: _.get(address, 'city', ''),
    cityDistrict: _.get(address, 'city_district', ''),
    cityDistrictType: _.get(address, 'city_district_type', ''),
    cityType: _.get(address, 'city_type', ''),
    country: _.get(address, 'country', ''),
    district: _.get(address, 'district', null) || _.get(address, 'area', null),
    districtType: _.get(address, 'district_type', null) || _.get(address, 'area_type', null),
    geolocation: _.get(address, 'geolocation', []),
    house: _.get(address, 'house', ''),
    houseType: _.get(address, 'house_type', ''),
    locality: _.get(address, 'locality', null) || _.get(address, 'settlement', null),
    localityType: _.get(address, 'locality_type', null) || _.get(address, 'settlement_type', null),
    postcode: _.get(address, 'postcode', '') || _.get(address, 'postal_code', ''),
    postCode: _.get(address, 'postcode', '') || _.get(address, 'postal_code', '') ||
      _.get(address, 'post_code', ''),
    region: _.get(address, 'region', ''),
    regionType: _.get(address, 'region_type', ''),
    regionCode: _.get(address, 'region_code', '') || _.get(address, 'kladr_id', '').slice(0, 2),
    street: _.get(address, 'street', ''),
    streetType: _.get(address, 'street_type', ''),
    isTemporary: _.get(address, 'is_temporary', false),
    value: _.get(address, 'value') || _.get(address, 'view') || '',
    view: _.get(address, 'value') || _.get(address, 'view') || '',
    full: _.get(address, 'full', ''),
    fiasId: _.get(address, 'fias_id', ''),
    fiasCode: _.get(address, 'fias_code', '')
  }

  if (!resolve.value) {
    resolve.value = generateValue(resolve, false)
    resolve.view = generateValue(resolve, false)
  }

  return resolve
}

export const addressMapApi = ({ address }) => {
  const resolve = {
    _id: address.id,
    id: address.id,
    value: address.value || address.view,
    view: address.value || address.view,
    apartment_number: address.apartmentNumber,
    flat: address.apartmentNumber,
    apartment_type: address.apartmentType,
    flat_type: address.apartmentType,
    block: address.block || address.blockNumber,
    block_number: address.block || address.blockNumber,
    block_type: address.blockType,
    city: address.city,
    city_district: address.cityDistrict,
    city_district_type: address.cityDistrictType,
    city_type: address.cityType,
    country: address.country,
    district: address.district,
    area: address.district,
    district_type: address.districtType,
    area_type: address.districtType,
    geolocation: address.geolocation,
    house: address.house,
    house_type: address.houseType,
    kladr_id: !address.isWithoutKladr ? address.kladrID : null,
    locality: address.locality,
    settlement: address.locality,
    locality_type: address.localityType,
    settlement_type: address.localityType,
    postal_code: address.postCode,
    postcode: address.postCode,
    post_code: address.postCode,
    region: address.region,
    region_code: address.regionCode,
    region_type: address.regionType || '',
    street: address.street,
    street_type: address.streetType,
    is_temporary: address.isTemporary,
    fias_id: address.fias_id || '',
    fias_code: address.fias_code || ''
  }
  if (!resolve.value) {
    resolve.value = generateValue(address, false)
    resolve.view = resolve.value
  }
  return resolve
}
