import _ from 'lodash'
import { isSafe, validRequired } from 'uikit'

const isRequiredIfExist = (prop, existProp) => (isSafe(existProp) && existProp !== '' ?
  isSafe(prop) && prop !== '' : true)

export const validationAddress = (value = '', forceHouseValidate = false) => {
  const country = _.get(value, 'country', '')
  const postCode = _.get(value, 'postCode', '')
  const regionCode = _.get(value, 'regionCode', null)
  const district = _.get(value, 'district', '')
  const districtType = _.get(value, 'districtType', null)
  const cityType = _.get(value, 'cityType', null)
  const city = _.get(value, 'city', '')
  const locality = _.get(value, 'locality', '')
  const localityType = _.get(value, 'localityType', '')
  const streetType = _.get(value, 'streetType', null)
  const street = _.get(value, 'street', '')
  const houseType = _.get(value, 'houseType', null)
  const house = _.get(value, 'house', '')
  const blockType = _.get(value, 'blockType', null)
  const block = _.get(value, 'block', '')
  const apartmentType = _.get(value, 'apartmentType', null)
  const apartmentNumber = _.get(value, 'apartmentNumber', '')

  const message = {}
  if (!isSafe(country) || country === '') message.country = 'Страна отсутствует'
  if (!isSafe(regionCode) || regionCode === '') message.regionCode = 'Регион отсутствует'

  if (!isRequiredIfExist(cityType, city)) message.cityType = 'Тип города отсутствует'
  if (!isRequiredIfExist(city, cityType)) message.city = 'Город отсутствует'

  if (!isRequiredIfExist(districtType, district)) message.districtType = 'Тип района отсутствует'
  if (!isRequiredIfExist(district, districtType)) message.district = 'Район отсутствует'

  if (!isRequiredIfExist(locality, localityType)) message.locality = 'Населённый пункт отсутствует'

  if (!isRequiredIfExist(apartmentType, apartmentNumber)) message.apartmentType = 'Тип квартиры отсутствует'
  if (!isRequiredIfExist(apartmentNumber, apartmentType)) message.apartmentNumber = 'Квартира отсутствует'

  if (!isRequiredIfExist(streetType, street)) message.streetType = 'Тип улицы отсутствует'
  if (!isRequiredIfExist(street, streetType)) message.street = 'Улица отсутствует'

  if (forceHouseValidate && validRequired(house)) message.house = 'Дом отсутствует'

  if (!isRequiredIfExist(houseType, house)) message.houseType = 'Тип дома отсутствует'
  if (!isRequiredIfExist(house, houseType)) message.house = 'Дом отсутствует'

  if (!isRequiredIfExist(blockType, block)) message.blockType = 'Тип строения отсутствует'
  if (!isRequiredIfExist(block, blockType)) message.block = 'Строение отсутствует'

  if (!isSafe(postCode) || postCode === '') message.postCode = 'Индекс отсутствует'
  if (postCode && postCode.length < 6) message.postCode = 'Длина индекса меньше 6'
  if (postCode && postCode.length > 6) message.postCode = 'Длина индекса больше 6'
  if (!_.isInteger(Number(postCode))) message.postCode = 'Не число'

  let resolveValidationMessage = ''
  _.each(message, m => {
    resolveValidationMessage = m
    return false
  })

  return {
    message,
    validation: resolveValidationMessage || null
  }
}
